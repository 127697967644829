import { acceptHMRUpdate, defineStore } from 'pinia'
import api from '@api'
import type {
  AutocompleteRequest,
  CarrierByHash,
  Carriers,
  CarriersAllListParams,
  CarriersListParams, LanguageEnum,
} from '@apiTypes'
import { supportedLanguages } from '@/plugins/i18n'

export const useCarriersStore = defineStore('carriers', {
  state: () => ({
    currentCarrierByHash: null as CarrierByHash | null,
  }),
  getters: {
    getCurrentLanguage: state => {
      return state.currentCarrierByHash?.preferredLanguage || supportedLanguages[0]
    },
  },
  actions: {
    setCurrentCarrier(carrier: CarrierByHash) {
      this.currentCarrierByHash = carrier
    },
    fetchCarriers(query: CarriersListParams) {
      return api.carriers.carriersList(query)
    },
    fetchCarrier(id: number) {
      return api.carriers.carriersRetrieve(id)
    },
    autocomplete(data: AutocompleteRequest) {
      return api.carriers.carriersAutocompleteCreate(data)
    },
    fetchAllCarriers(query: CarriersAllListParams) {
      return api.carriers.carriersAllList(query)
    },
    addCarrier(data: Carriers.CarriersCreate.RequestBody) {
      return api.carriers.carriersCreate(data)
    },
    updateCarrier(id: number, params: Carriers.CarriersPartialUpdate.RequestBody) {
      return api.carriers.carriersPartialUpdate(id, params)
    },
    deleteCarrier(id: number) {
      return api.carriers.carriersDestroy(id)
    },
    setActive(id: number, isActive: boolean) {
      return api.carriers.carriersPartialUpdate(id, { isActive })
    },
    resendConsent(id: number) {
      return api.privacy.privacyConsentsRequestResendCreate({ carrierId: id })
    },
    fetchCurrentCarrierByHash(hash: string) {
      return api.byHash.byHashCarriersRetrieve(hash, { skipAuthToken: true }).then(response => {
        this.setCurrentCarrier(response.data)

        return response
      })
    },
    updateCarrierByHashLanguage(hash: string, preferredLanguage: LanguageEnum) {
      return api.byHash.byHashCarriersPartialUpdate(hash, { preferredLanguage }, { skipAuthToken: true, skipAuthRefresh: true }).then(result => {
        this.setCurrentCarrier(result.data)

        return result.data
      })
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useCarriersStore, import.meta.hot))
